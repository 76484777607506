<template>
  <v-container>
    <loading :active.sync="loading"></loading>
    <v-row align="center" justify="center" class="text-center" no-gutters>
      <v-card width="90%" class="pa-10">
        <v-row align="center" justify="center" class="text-center" no-gutters>
          <v-img
            src="@/assets/logo_survey.png"
            max-width="180px"
            max-height="180px"
          />
        </v-row>
        <v-form
          ref="createForm"
          v-model="checkValidate"
          :lazy-validation="lazy"
        >
          <p style="font-size: 24px" class="headtitle">
            แบบตรวจแนะนำการปฏิบัติของชุดปฏิบัติการชุมชน
          </p>
          <p style="font-size: 24px" class="headtitle">(สำหรับหัวหน้าสถานี)</p>
          <!-- <p style="font-size: 24px" class="headtitle">
            เพื่อแก้ไขปัญหายาเสพติด แบบครบวงจร ตามยุทธศาสตร์ชาติ
          </p> -->
          <!-- <p style="font-size: 24px" class="headtitle">
            ตำรวจภูธรภาค {{ checklogin.station }}
          </p> -->
          <br />

          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="สถานี"
                outlined
                v-model="data.station"
                :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-menu
                ref="menufrom"
                v-model="menufrom"
                :close-on-content-click="false"
                :return-value.sync="data.datefrom"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data.datefrom"
                    label="วันที่บันทึกข้อมูลล่าสุด"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    :disabled="true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="data.datefrom" no-title scrollable>
                  <v-btn text color="primary" @click="menufrom = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menufrom.save(data.datefrom)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-text-field
                label="ผู้ที่บันทึกข้อมูลล่าสุด"
                outlined
                v-model="data.staffName"
                :disabled="true"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <h3 class="headtitle">1) หัวหน้าสถานี</h3>
          </v-row>
          <v-row>
            <h3>
              1.1) เพื่อประเมิน หน.สภ/หน.สน. มีความเข้าใจและตอบสนองต่อ
              โครงการอย่างไรบ้าง
            </h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">1. มีการสอบถามประชาชน</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามประชาชนในชุมชน</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_1_1_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_1_1_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">2. มีการสอบถามชุดปฏิบัติการ</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจแฟ้ม</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_1_1_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_1_1_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">3. มีการนำเสนอของ หัวหน้าสถานี</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: จากการนำเสนอ</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_1_1_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_1_1_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. มีการตรวจคำสั่ง,การประชุมสั่งการ</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: เอกสารและภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_1_1_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_1_1_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >5. มีการประสานงานกับภาคีเครือข่าย</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: เอกสารและภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_1_1_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_1_1_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >6. มีการบริหารและเบิกจ่ายงบประมาณ</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_1_1_6">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_1_1_6"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1.1.7 มีความเข้าใจและมีการใช้งานระบบ Web และ App ของโครงการ</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบกับ หน.สภ./สน.</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_1_1_7">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_1_1_7"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3 style="font-weight: 800">
              1. ผลการปฏิบัติงานของ หัวหน้าสถานี ตามแบบตรวจแนะนำ (1-7)
            </h3>
          </v-row>
          <v-row class="text-left" no-gutters>
            <!-- <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data2.q_1_re
              disabledview">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col> -->
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.1 สรุปผลการปฏิบัติงาน</span>
              <v-textarea
                v-model="data2.q_1_1"
                disabled
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.2 จุดเด่นในการปฏิบัติงาน</span>
              <v-textarea
                v-model="data2.q_1_2"
                disabled
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.3 แนวทางการเสริมจุดเด่น</span>
              <v-textarea
                v-model="data2.q_1_3"
                disabled
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.4 จุดที่ควรพัฒนา</span>
              <v-textarea
                v-model="data2.q_1_4"
                disabled
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <span>1.5 แนวทางการพัฒนา</span>
              <v-textarea
                v-model="data2.q_1_5"
                disabled
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <br />
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">
                การประเมินผลการปฏิบัติงานตามขั้นตอนการปฏิบัติ
              </h3>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >1. มีผลการดำเนินงานของ 1 ) หัวหน้าสถานี ตามแบบตรวจแนะนำ (1-7)
                </b>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <v-radio-group v-model="data.review_1">
                  <v-radio value="1">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text">น้อยที่สุด </strong
                        >ไม่มีการดำเนินงานตามขั้นตอนที่กำหนดตามแบบตรวจแนะนำ
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="2">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text">น้อย </strong
                        >มีการดำเนินงานตามขั้นตอนที่กำหนดบางข้อที่กำหนดตามแบบตรวจแนะนำ
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="3">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text">ปานกลาง </strong
                        >มีการดำเนินงานตามขั้นตอน ที่กำหนด
                        ครบทุกข้อที่กำหนดตามแบบตรวจแนะนำ
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="4">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text">มาก </strong
                        >มีการดำเนินครบถ้วนทุกขั้นตอน
                        และมีผลการดำเนินงานทั้งเอกสารและข้อมูลในระบบรายงานออนไลน์
                        ถูกต้อง
                      </div>
                    </template>
                  </v-radio>
                  <v-radio value="5">
                    <template v-slot:label>
                      <div>
                        <strong class="black--text">มากที่สุด </strong
                        >มีการดำเนิน ตามระดับ 4 และ มีรายงานสรุปผลการดำเนินงาน
                        การวิเคราะห์จุดเด่น จุดควรพัฒนา เผยแพร่
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">1. หัวข้อการปฏิบัติงานตามขั้นตอน</h3>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">1. มีการสอบถามประชาชน</b>
                <v-radio-group v-model="data2.b_1">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2. มีการสอบถามชุดปฏิบัติการ</b>
                <v-radio-group v-model="data2.b_2">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">3. มีการนำเสนอของ หัวหน้าสถานี</b>
                <v-radio-group v-model="data2.b_3">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4. มีการตรวจคำสั่ง,การประชุมสั่งการ</b
                >
                <v-radio-group v-model="data2.b_4">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">5. มีการประสานงานกับภาคีเครือข่าย</b>
                <v-radio-group v-model="data2.b_4">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">6. มีการบริหารและเบิกจ่ายงบประมาณ</b>
                <v-radio-group v-model="data2.b_6">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >7. มีความเข้าใจและมีการใช้งานระบบ web และ app ของโครงการ</b
                >
                <v-radio-group v-model="data2.b_7">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-row>
          <v-divider></v-divider>
          <br />
          <v-row>
            <h3 class="headtitle">2) ขั้นเตรียมการ</h3>
          </v-row>
          <v-row>
            <h3>2.1) ระดับอำเภอ/เขต ศูนย์ยาเสพติดอำเภอ/เขต</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">1. มีการทำงานอย่างบูรณาการ</b>
              <br />
              <p style="font-size: 16px">
                วิธีตรวจสอบ: สุ่มสอบถามผู้เกี่ยวข้อง
              </p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_1_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_1_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. มีการประชุมร่วม นายอำเภอ/ผอ.เขต ในนาม หน.ศูนย์ฯ
                และสั่งการด้วยตัวเองโดยมี ตร.,สธ. และภาคีเครือข่ายร่วมด้วย</b
              >
              <br />
              <p style="font-size: 16px">
                วิธีตรวจสอบ: ดูจากแฟ้มคำสั่งและภาพถ่าย
              </p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_1_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_1_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3. มีคำสั่ง ศูนย์ยาเสพติดในระดับ อำเภอ/เขต และจังหวัด/กทม.
                ถูกต้อง</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: แฟ้มคำสั่ง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_1_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_1_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. มีการประสานงานระดับ จังหวัด,อำเภอ,เขต</b
              >
              <br />
              <p style="font-size: 16px">
                วิธีตรวจสอบ: แฟ้มคำสั่ง และหรือภาพถ่าย
              </p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_1_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_1_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>2.2) ชุดปฏิบัติการ</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีการแต่งตั้งชุดปฏิบัติการมีความเหมาะสม</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบกับ ชป.</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_2_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_2_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. มีความเข้าใจในกระบวนการทำงานของโครงการชุมชนยั่งยืน</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบกับ ชป.</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_2_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_2_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3. มีความตื่นตัว,การเรียนรู้ และการเข้าหาประชาชน</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ประชาชน,ผู้นำชุมชน</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_2_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_2_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. มีการประชาสัมพันธ์ การใช้ web และ App ของโครงการ</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบกับ หน.ชป.</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_2_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_2_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2.2.5 มีความเข้าใจและการใช้ web และ app โดยประชาชน</b
              >
            </v-col>
            <br />
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">5.1 มีการร้องเรียนแจ้งเบาะแส</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ประชาชน, ผู้นำชุมชน</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_2_5_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_2_5_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">5.2 มีการกรอกแบบประเมิน</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ประชาชน, ผู้นำชุมชน</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_2_5_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_2_5_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>2.3)สถานที่ตั้งศูนย์ฯ</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีความเหมาะสม พิจารณาจากเป็นจุดศูนย์รวม
                มีการเดินทางเข้าออกสะดวก มีสถานที่จัดกิจกรรมและประชุมได้
                ป้ายศูนย์และป้ายบอกทาง</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_3_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_3_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>2.4)อุปกรณ์</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มียานพาหนะ ประจำและสถาพสมบูรณ์</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">2. มีบอร์ดป้ายคณะกรรมการคุ้ม</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">3. มีบอร์ดแผนที่เดินดิน</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">4. มีบอร์ดแผนผังสรุปผลการ x-ray</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >5. มีบอร์ดตารางเวลาการทำงาน (Timeline)</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">6. มีบอร์ดความร่วมมือ (MOU)</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_6">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_6"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <!-- <v-row> -->
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >7. มีแฟ้มเอกสารที่จัดเก็บอย่างเป็นระบบ</b
              >
              <br />
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >7.1 เอกสารคำสั่งแต่งตั้งคณะกรรมการคุ้ม</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_7_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_7_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">2.4.3 คณะกรรมการกลั่นกรอง</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_7_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_7_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">7.3 สำมะโนประชากร</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_7_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_7_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >7.4 สมุดบันทึกการตรวจเยี่ยมของ ผู้บังคับบัญชา</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_7_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_7_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">7.5 สมุดบันทึกประจำวัน</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_4_7_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_4_7_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>2.5) การสืบสภาพชุมชน</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีการออกพบปะผู้นำชุมชนอย่างน้อย 2 ครั้ง</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบแฟ้ม,ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_5_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_5_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">2. มีกิจกรรมกับประชาคมกลุ่มย่อย</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามผู้นำชุมชนแลคุ้ม</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_5_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_5_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2.5.3มีการใช้ข้อมูล เหตุผลในการเลือกชุมชน</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบกับ ชป.</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_5_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_5_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. มีการเดินเผชิญสืบ/จัดทำแผนที่เดินดิน</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสอบกับ ชป.</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_5_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_5_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >5. กิจกรรมมีส่วนร่วมกับประชาชน เช่น
                กิจกรรมจิตอาสาพัฒนา/ร่วมประเพณี
                วัฒนธรรมชุมชนท้องถิ่น/บริการประชาชน</b
              >
              <br />
              <p style="font-size: 16px">
                วิธีตรวจสอบ: ประชาชน,ผู้นำชุมชน,ผู้นำศาสนา
              </p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_5_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_5_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >6. มีการทำแบบสอบถามและการรวบรวมข้อมูลในชุมชน</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ผลการสำรวจ</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_5_6">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_5_6"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row><h3>2.6) กระบวนการคุ้ม</h3></v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีการจัดทำป้ายคุ้มและติตตั้งจุดที่เหมาะสม</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สุ่มตรวจ</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_6_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_6_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. มีคำสั่งแต่งตั้งคณะกรรมการคุ้ม</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจแฟ้ม</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_6_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_6_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">3. มีป้ายบอร์ดคณะกรรมการคุ้ม</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจสถานที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_6_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_6_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4.
                มีการสร้างความเข้าใจในการทำงานและบทบาทหน้าที่ของคณะกรรมการคุ้ม</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สุ่มตรวจคุ้ม</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_6_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_6_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >5. การแบ่งคุ้มมีความเหมาะสม 1คุ้ม ควรมีครัวเรือนไม่เกิน 30
                หลังคา</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจแฟ้ม</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_6_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_6_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >6. มีแฟ้มบัญชีสำมะโนประชากรประจำคุ้ม</b
              >
              <br />
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">6.1 ข้อมูลประชากรตาม ทร.14</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจแฟ้ม</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_6_6_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_6_6_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">6.2 ข้อมูลครัวเรือนประชากรจริง</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ตรวจแฟ้ม</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_2_6_6_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_2_6_6_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-row>
              <h3 style="font-weight: 800">
                2. ผลการปฏิบัติงาน ขั้นเตรียมการ ตามแบบตรวจแนะนำ (2.1 – 2.6)
              </h3>
            </v-row>
            <v-row class="text-left" no-gutters>
              <!-- <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data2.q_2_re
              disabledview">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col> -->
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.1 สรุปผลการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data2.q_2_1"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.2 จุดเด่นในการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data2.q_2_2"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.3 แนวทางการเสริมจุดเด่น</span>
                <v-textarea
                  v-model="data2.q_2_3"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.4 จุดที่ควรพัฒนา</span>
                <v-textarea
                  v-model="data2.q_2_4"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>2.5 แนวทางการพัฒนา</span>
                <v-textarea
                  v-model="data2.q_2_5"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">
                การประเมินผลการปฏิบัติงานตามขั้นตอนการปฏิบัติ
              </h3>
              <v-row class="text-left pt-4" no-gutters>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <b style="font-size: 18px"
                    >2. มีผลการดำเนินงาน 2) ขั้นเตรียมการ ตามแบบตรวจแนะนำ
                    (2.1-2.6)
                  </b>
                </v-col>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <v-radio-group v-model="data.review_2">
                    <v-radio value="1">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">น้อยที่สุด </strong
                          >ไม่มีการดำเนินงานตามขั้นตอนที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="2">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">น้อย </strong
                          >มีการดำเนินงานตามขั้นตอนที่กำหนดบางข้อที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="3">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">ปานกลาง </strong
                          >มีการดำเนินงานตามขั้นตอน ที่กำหนด
                          ครบทุกข้อที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="4">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">มาก </strong
                          >มีการดำเนินครบถ้วนทุกขั้นตอน
                          และมีผลการดำเนินงานทั้งเอกสารและข้อมูลในระบบรายงานออนไลน์
                          ถูกต้อง
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="5">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">มากที่สุด </strong
                          >มีการดำเนิน ตามระดับ 4 และ มีรายงานสรุปผลการดำเนินงาน
                          การวิเคราะห์จุดเด่น จุดควรพัฒนา เผยแพร่
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">2. หัวข้อการปฏิบัติงานตามขั้นตอน</h3>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2.1 ระดับอำเภอ/เขต ศูนย์ยาเสพติดอำเภอ/เขต (มีครบข้อย่อย
                  1-4)</b
                >
                <v-radio-group v-model="data2.b_2_1">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2.2 ชุดปฏิบัติการ (มีครบข้อย่อย 1-3 หรือเพิ่มเติมข้อย่อย 4-5
                  )</b
                >
                <v-radio-group v-model="data2.b_2_2">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">2.3 สถานที่ตั้งศูนย์ฯ </b>
                <v-radio-group v-model="data2.b_2_3">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2.4 อุปกรณ์ (มีครบข้อย่อย 1-7(7.1-7.5))</b
                >
                <v-radio-group v-model="data2.b_2_4">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2.5 การสืบสภาพชุมชน (มีครบข้อย่อย 1-6)</b
                >
                <v-radio-group v-model="data2.b_2_5">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2.6 กระบวนการคุ้ม (มีครบข้อย่อย 1-6)</b
                >
                <v-radio-group v-model="data2.b_2_6">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >2.7 มีรายงานสรุปผลการดำเนินงาน การปฏิบัติงานตามขั้นตอน
                </b>
                <v-radio-group v-model="data2.b_2_7">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-row>
          <br />
          <v-row>
            <v-divider></v-divider>
            <br />
          </v-row>
          <v-row>
            <h3 class="headtitle">3.)ขั้นตอนการปฏิบัติ</h3>
          </v-row>
          <v-row>
            <h3>3.1) กระบวนการค้นหาผู้ค้า ผู้เสพแบบครบวงจร</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">1. มีการบันทึก MOU ครัวเรือน</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. มีการตรวจปัสสาวะ 100%
                โดยมีประชาคมยกเว้นกลุ่มผู้ไม่ต้องตรวจ (เกณฑ์อายุ 12 – 65 ปี)</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3. มีกระบวนการผ้าป่าเบาะแส (แจ้งข้อมูลผ่านซองจดหมาย)</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. มีสืบค้นจากฐานข้อมูลระบบ Crimes (3 ปีย้อนหลัง)</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูแฟ้มเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >5. มีประชาสัมพันธ์ให้ออกมารายงานตัว</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามผู้นำชุมชน</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >6. มีข้อมูลจากงานสืบสวน/ผู้นำชุมชน/จากฝ่ายปกครอง</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามผู้นำชุมชน</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_6">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_6"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">7. มีข้อมูลบุคคลพ้นโทษ</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_7">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_7"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >8. มีกระบวนการกลั่นกรองพลเมืองสีขาว</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_8">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_8"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >9. มีการกำหนดกติการการตรวจปัสสาวะ</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สาธารณสุข, ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_9">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_9"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >10. มีแนวทางการตรวจปัสสาวะให้เร็วและครบถ้วน</b
              >
              <br />
              <p style="font-size: 16px">
                วิธีตรวจสอบ: สอบถามประชาชน, ผู้นำชุมชน
              </p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_1_10">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_1_10"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <h3>3.2) กระบวนกลั่นกรองบุคคลและครัวเรือนสีขาว</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีคำสั่งแต่งตั้งคณะกรรมการกลั่นกรอง</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_2_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_2_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">2. มีการประชุมพิจารณากลั่นกรอง</b>
              <br />
              <p style="font-size: 16px">
                วิธีตรวจสอบ: ภาพถ่าย/เอกสาร พูดคุยกับหัวหน้าคุ้ม
              </p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_2_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_2_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3. จัดให้มีการมอบบัตรพลเมืองสีขาว</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_2_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_2_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. จัดให้มีการมอบป้ายครัวเรือนสีขาว</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_2_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_2_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">5. จัดให้มีการมอบป้ายคุ้มสีขาว</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_2_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_2_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>3.3) กระบวนการบำบัด</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3.3.1 มีฝ่ายสาธารณสุขคัดกรองนำเข้าสู่กระบวนการบำบัด</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3.3.1 มีฝ่ายสาธารณสุขคัดกรองนำเข้าสู่กระบวนการบำบัด
                การคัดกรองข้อมูลผู้เสพ 4 กลุ่ม ในระบบ มท.(NISPA) สธ.
                ระบบข้อมูลบำบัดรักษาและฟื้นฟูผู้ติดยาเสพติดของประเทศ (บสต.)
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. มีการนำบัญชีรายชื่อผู้สมัครใจบำบัดไปลง
                ประจำวันเป็นหลักฐานที่ สน. สภ. ทุกราย (ตามประกาศ คสช. 108/57)</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3.3.3 มีคำสั่ง ศอ. ปส. อำเภอ/เขต ประกาศให้ ศปก
                ชุมชนยั่งยืนเป็นศูนย์บำบัดฟื้นฟูผู้ป่วยยาเสพติดประจำชุมชน</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. มีการจัดทำหลักสูตรร่วมกับฝ่ายสาธารณสุข</b
              >
              <br />
              <!-- <p style="font-size: 16px">
                วิธีตรวจสอบ: ภาพถ่าย/เอกสาร สอบถามผู้นำชุมชน ภาคีเครือข่าย
              </p> -->
            </v-col>
            <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col> -->
            <!-- <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col> -->
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">4.1 มีตารางบำบัดทางการแพทย์</b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_4_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_4_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4.2 มีตารางบำบัดด้วยกระบวนการชุมชนบำบัด</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_4_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_4_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4.3
                มีตารางบำบัดด้วยการฟื้นฟูร่างกาย-จิตใจและกายจิตสังคมบำบัด</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_4_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_4_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >5. มีการจัดทำสมุดประจำตัวผู้ป่วย (ผู้เข้าบำบัด)
                เพื่อบันทึกผลกาตรวจปัสสาวะ/กิจกรรม
                มีตารางการนัดหมายหรือบัตรนัด</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3.3.6 มีประชาชน-ผู้ป่วย-เยาววชนคนดี ร่วมกิจกรรม
                ชุมชนบำบัดอย่างกลมกลืนและต่อเนื่อง</b
              >
              <br />
              <p style="font-size: 16px">
                วิธีตรวจสอบ: ภาพถ่าย/เอกสาร สัมภาษณ์ผู้นำชุมชน
              </p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_3_6">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_3_6"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row><h3>4.)กิจกรรมส่งเสริมชุมชนเข้มแข็ง</h3></v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีการวางแผนการประชุมและมอบหน้าที่ การตรวจ, การจับ,
                การตั้งด่าน, การระงับเหตุ, การบำบัดและการพัฒนา</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_4_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_4_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. กิจกรรมเสริมสร้างภูมิคุ้มกันให้กับชุมชน เช่น
                การให้ความรู้เยาวชน, นักเรียน, โรงงาน โครงการต่าง ๆ, อบรมการใช้
                Web และ App</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_4_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_4_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3. มีการพัฒนาและการแก้ไขปัญหาในชุมชน</b
              >
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_3_4_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_3_4_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-row>
              <h3 style="font-weight: 800">
                3. ผลการปฏิบัติงาน ขั้นปฏิบัติการ ตามแบบตรวจแนะนำ (3.1 – 3.4 )
              </h3>
            </v-row>
            <v-row class="text-left" no-gutters>
              <!-- <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data2.q_3_re
              disabledview">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col> -->
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.1 สรุปผลการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data2.q_3_1"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.2 จุดเด่นในการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data2.q_3_2"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.3 แนวทางการเสริมจุดเด่น</span>
                <v-textarea
                  v-model="data2.q_3_3"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.4 จุดที่ควรพัฒนา</span>
                <v-textarea
                  v-model="data2.q_3_4"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>3.5 แนวทางการพัฒนา</span>
                <v-textarea
                  v-model="data2.q_3_5"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">
                การประเมินผลการปฏิบัติงานตามขั้นตอนการปฏิบัติ
              </h3>
              <v-row class="text-left pt-4" no-gutters>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <b style="font-size: 18px"
                    >3. มีผลการดำเนินงาน 3)ขั้นปฏิบัติการ ตามแบบตรวจแนะนำ
                    (3.1-3.4)
                  </b>
                  <!-- <br /> -->
                  <!-- <p style="font-size: 16px">วิธีตรวจสอบ: เอกสาร ภาพถ่าย</p> -->
                </v-col>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <v-radio-group v-model="data.review_3">
                    <v-radio value="1">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">น้อยที่สุด </strong
                          >ไม่มีการดำเนินงานตามขั้นตอนที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="2">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">น้อย </strong
                          >มีการดำเนินงานตามขั้นตอนที่กำหนดบางข้อที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="3">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">ปานกลาง </strong
                          >มีการดำเนินงานตามขั้นตอน ที่กำหนด
                          ครบทุกข้อที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="4">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">มาก </strong
                          >มีการดำเนินครบถ้วนทุกขั้นตอน
                          และมีผลการดำเนินงานทั้งเอกสารและข้อมูลในระบบรายงานออนไลน์
                          ถูกต้อง
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="5">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">มากที่สุด </strong
                          >มีการดำเนิน ตามระดับ 4 และ มีรายงานสรุปผลการดำเนินงาน
                          การวิเคราะห์จุดเด่น จุดควรพัฒนา เผยแพร่
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">3. สรุปผลการประเมิน ขั้นปฏิบัติการ</h3>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3.1 กระบวนการค้นหาผู้ค้า ผู้เสพแบบครบวงจร (มีครบข้อย่อย
                  1-10)</b
                >
                <v-radio-group v-model="data2.b_3_1">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3.2 กระบวนกลั่นกรองบุคคลและครัวเรือนสีขาว (มีครบข้อย่อย
                  1-5)</b
                >
                <v-radio-group v-model="data2.b_3_2">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3.3 กระบวนการบำบัด (มีครบข้อย่อย 1-6)</b
                >
                <v-radio-group v-model="data2.b_3_3">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3.4 กิจกรรมส่งเสริมชุมชนเข้มแข็ง (มีครบข้อย่อย 1-3)</b
                >
                <v-radio-group v-model="data2.b_3_4">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >3.5 มีรายงานสรุปผลการดำเนินงาน การปฏิบัติงานตามขั้นตอน
                </b>
                <v-radio-group v-model="data2.b_3_5">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-row>
          <!-- <br />
          <v-row>
            <v-divider></v-divider>
            <br />
          </v-row> -->
          <br />
          <v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <h3 class="headtitle">4.) ขั้นส่งต่อความยั่งยืน</h3>
          </v-row>
          <v-row>
            <h3>4.1) กระบวนการส่งต่อความยั่งยืน</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีป้าย ข้อมูลพื้นฐานของชุมชน -
                สรุปผลการค้นหาผู้เข้าร่วมบำบัดตามโครงการ
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูสถานที่/ดูเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. มีแผนการตรวจปัสสาวะ
                โดยภาคีเครือข่ายทุกสัปดาห์และติดตามผลอย่างต่อเนื่อง
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย/เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3. มีสมุดคู่มือประจำผู้ป่วย (ส่งมอบ หัวหน้าคุ้มเก็บรักษา)
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามผู้นำชุมชน</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >4. มีคำสั่ง ศอ.ปส. อำเภอ/เขต เพื่อติดตาม-ประเมินผล-ประสานงาน
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามภาคีเครือข่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_4">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_4"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >5. จัดให้มีการนำเข้าวาระการประชุมในระดับ หมู่บ้าน/ชุมชน
                ตำบล/แขวง อำเภอ/เขต ทุกเดือน
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามผู้ร่วมโครงการ</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_5">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_5"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >6. จัดให้มีการนำเข้าวาระการประชุมในระดับ อำเภอ/เขต
                จังหวัด/กรุงเทพฯ
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: สอบถามผู้ร่วมโครงการ</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_6">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_6"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >7. มีประชาคม ระดับคุ้ม ระดับหมู่บ้าน ชาวบ้าน เพื่อสร้างกฎ
                กติกา ธรรมนูญ หมู่บ้าน/ชุมชน
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_7">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_7"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>8) มีการส่งเสริมคุณภาพชีวิตของผู้บำบัด</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">8.1 จัดหางาน </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_8_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_8_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">8.2 ฝึกอาชีพ </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_8_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_8_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">8.3 สร้างโอกาสทางการศึกษา </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_8_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_8_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <h3>9.) การประสานความช่วยเหลือหน่วยงานต่าง ๆ</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">9.1 หน่วยงานภาครัฐ </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_9_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_9_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">9.2 หน่วยงานภาคเอกชน </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูเอกสาร/ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_9_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_9_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <h3>10.) การประสานความช่วยเหลือหน่วยงานต่าง ๆ</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">10. มีการจัดระบบ ช่องทางสื่อสาร </b>
              <br />
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">10.1 ระบบไลน์ เบอรโทรศัพท์ </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูโซเชียล</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_10_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_10_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px">10.2 เพจเฟสบุ๊ค </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูโซเชียล</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_10_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_10_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">10.3 อื่นๆ </b>
                <br />
                <p style="font-size: 16px; color: blue">
                  วิธีตรวจสอบ: ดูโซเชียล
                </p>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                คะแนน
                <v-radio-group
                  :rules="rules.required"
                  row
                  v-model="data.q_4_1_10_3"
                >
                  <v-radio value="1" label="1"></v-radio>
                  <v-radio value="2" label="2"></v-radio>
                  <v-radio value="3" label="3"></v-radio>
                  <v-radio value="4" label="4"></v-radio>
                  <v-radio value="5" label="5"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="6" sm="6" xs="12">
                <v-textarea
                  disabled
                  v-model="data.r_4_1_10_3"
                  label="หมายเหตุ"
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >11 สถานีตำรวจออกคำสั่งให้ จนท. ตำรวจ ตรวจเยี่ยม
                อย่างน้อยเดือนละ 2 ครั้ง
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: เอกสาร</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_1_11">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_1_11"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>4.2) การปรับสภาพระบบนิเวศเชิงลบ</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1. มีการปรับปรุงแสงส่องสว่าง จุดเสี่ยง
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ดูพื้นที่จริง</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_2_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_2_1"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >2. มีการปรับภูมิทัศน์ สภาพแวดล้อมชุมชน เช่น ความโปร่ง โล่ง
                สะอาด สวยงาม อย่างต่อเนื่อง
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_2_2">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_2_2"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >3. การดำเนินการกับบุคคลที่ก่อให้เกิดความไม่เรียบร้อย
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: เอกสาร/ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_2_3">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_2_3"
                label="หมายเหตุ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <h3>4.3)กระบวนการรักษาความปลอดภัยและความสงบเรียบร้อยในชุมชน</h3>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-col cols="12" md="12" sm="12" xs="12">
              <b style="font-size: 18px"
                >1.
                มีการแสวงหาความร่วมมือจากองค์กรปกครองส่วนท้องถิ่นหรือภาคเอกชนในพื้นที่
              </b>
              <br />
              <p style="font-size: 16px">วิธีตรวจสอบ: เอกสาร แผน ภาพถ่าย</p>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-radio-group disabled v-model="data.q_4_3_1">
                <v-radio value="1" label="มี"></v-radio>
                <v-radio value="0" label="ไม่มี"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <v-textarea
                disabled
                v-model="data.r_4_3_1"
                label="หมายเหตุ ต้องมีหนังสือนำส่งโครงการในการดำเนินการ"
                rows="3"
                outlined
              ></v-textarea>
            </v-col>
            <v-row>
              <h3 style="font-weight: 800">
                4. ผลการปฏิบัติงาน ขั้นส่งต่อความยั่งยืน ตามแบบตรวจแนะนำ (4.1 –
                4.3 )
              </h3>
            </v-row>
            <v-row class="text-left" no-gutters>
              <!-- <v-col cols="12" md="12" sm="12" xs="12">
              <v-radio-group v-model="data2.q_4_re
              disabledview">
                <v-radio value="1" label="น้อยที่สุด"></v-radio>
                <v-radio value="2" label="น้อย"></v-radio>
                <v-radio value="3" label="ปานกลาง"></v-radio>
                <v-radio value="4" label="มาก"></v-radio>
                <v-radio value="5" label="มากที่สุด"></v-radio>
              </v-radio-group>
            </v-col> -->
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.1 สรุปผลการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data2.q_4_1"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.2 จุดเด่นในการปฏิบัติงาน</span>
                <v-textarea
                  v-model="data2.q_4_2"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.3 แนวทางการเสริมจุดเด่น</span>
                <v-textarea
                  v-model="data2.q_4_3"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.4 จุดที่ควรพัฒนา</span>
                <v-textarea
                  v-model="data2.q_4_4"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <span>4.5 แนวทางการพัฒนา</span>
                <v-textarea
                  v-model="data2.q_4_5"
                  disabled
                  rows="3"
                  outlined
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">
                การประเมินผลการปฏิบัติงานตามขั้นตอนการปฏิบัติ
              </h3>
              <v-row class="text-left pt-4" no-gutters>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <b style="font-size: 18px"
                    >4. มีผลการดำเนินงาน 4)ขั้นส่งต่อความยั่งยืน ตามแบบตรวจแนะนำ
                    (4.1-4.3)
                  </b>
                  <!-- <br /> -->
                  <!-- <p style="font-size: 16px">วิธีตรวจสอบ: เอกสาร ภาพถ่าย</p> -->
                </v-col>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <v-radio-group v-model="data.review_4">
                    <v-radio value="1">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">น้อยที่สุด </strong
                          >ไม่มีการดำเนินงานตามขั้นตอนที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="2">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">น้อย </strong
                          >มีการดำเนินงานตามขั้นตอนที่กำหนดบางข้อที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="3">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">ปานกลาง </strong
                          >มีการดำเนินงานตามขั้นตอน ที่กำหนด
                          ครบทุกข้อที่กำหนดตามแบบตรวจแนะนำ
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="4">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">มาก </strong
                          >มีการดำเนินครบถ้วนทุกขั้นตอน
                          และมีผลการดำเนินงานทั้งเอกสารและข้อมูลในระบบรายงานออนไลน์
                          ถูกต้อง
                        </div>
                      </template>
                    </v-radio>
                    <v-radio value="5">
                      <template v-slot:label>
                        <div>
                          <strong class="black--text">มากที่สุด </strong
                          >มีการดำเนิน ตามระดับ 4 และ มีรายงานสรุปผลการดำเนินงาน
                          การวิเคราะห์จุดเด่น จุดควรพัฒนา เผยแพร่
                        </div>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-card>
          </v-row>
          <v-row class="text-left pt-4" no-gutters>
            <v-card outlined class="pa-2 elevation-4" width="100%">
              <h3 class="headtitle">
                4. สรุปผลการประเมิน ขั้นส่งต่อความยั่งยืน
              </h3>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4.1 กระบวนการส่งต่อความยั่งยืน (มีครบข้อย่อย 1-11)</b
                >
                <v-radio-group v-model="data2.b_4_1">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4.2 การลดระบบนิเวศเชิงลบ (มีครบข้อย่อย 1-3)</b
                >
                <v-radio-group v-model="data2.b_4_2">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px">4.3 การแสวงหาความร่วมมือ</b>
                <v-radio-group v-model="data2.b_4_3">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" sm="12" xs="12">
                <b style="font-size: 18px"
                  >4.4 มีรายงานสรุปผลการดำเนินงาน การปฏิบัติงานตามขั้นตอน
                </b>
                <v-radio-group v-model="data2.b_4_4">
                  <v-radio value="1" label="มี"></v-radio>
                  <v-radio value="0" label="ไม่มี"></v-radio>
                </v-radio-group>
              </v-col>
            </v-card>
          </v-row>
          <br />
          <v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <h3 class="headtitle">อัพโหลดรูปภาพกิจกรรมโครงการ (4รูป)</h3>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 1</span>
              <v-img
                :src="data.imgUrl1"
                height="250"
                contain
                v-if="data.imgUrl1 && data.imgUrl1.length < 200"
              ></v-img>
              <v-img
                :src="showImage1"
                height="250"
                contain
                v-if="!data.imgUrl1"
              ></v-img>
              <v-img
                :src="showImage1"
                height="250"
                contain
                v-if="data.imgUrl1 && data.imgUrl1.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName1"
                @click="changePic1()"
                :label="data.imageName1"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure1"
                accept="image/*"
                @change="showPicture1($event)"
                style="display: none"
              />
            </v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 2</span>
              <v-img
                :src="data.imgUrl2"
                height="250"
                contain
                v-if="data.imgUrl2 && data.imgUrl2.length < 200"
              ></v-img>
              <v-img
                :src="showImage2"
                height="250"
                contain
                v-if="!data.imgUrl2"
              ></v-img>
              <v-img
                :src="showImage2"
                height="250"
                contain
                v-if="data.imgUrl2 && data.imgUrl2.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName2"
                @click="changePic2()"
                :label="data.imageName2"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure2"
                accept="image/*"
                @change="showPicture2($event)"
                style="display: none"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 3</span>
              <v-img
                :src="data.imgUrl3"
                height="250"
                contain
                v-if="data.imgUrl3 && data.imgUrl3.length < 200"
              ></v-img>
              <v-img
                :src="showImage3"
                height="250"
                contain
                v-if="!data.imgUrl3"
              ></v-img>
              <v-img
                :src="showImage3"
                height="250"
                contain
                v-if="data.imgUrl3 && data.imgUrl3.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName3"
                @click="changePic3()"
                :label="data.imageName3"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure3"
                accept="image/*"
                @change="showPicture3($event)"
                style="display: none"
              />
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <span>อัพโหลดรูปภาพที่ 4</span>
              <v-img
                :src="data.imgUrl4"
                height="250"
                contain
                v-if="data.imgUrl4 && data.imgUrl4.length < 200"
              ></v-img>
              <v-img
                :src="showImage4"
                height="250"
                contain
                v-if="!data.imgUrl4"
              ></v-img>
              <v-img
                :src="showImage4"
                height="250"
                contain
                v-if="data.imgUrl4 && data.imgUrl4.length > 200"
              ></v-img>
              <v-text-field
                v-model="data.imageName4"
                @click="changePic4()"
                :label="data.imageName4"
                prepend-icon="mdi-camera"
                readonly
              >
              </v-text-field
              ><input
                type="file"
                ref="image"
                id="picTure4"
                accept="image/*"
                @change="showPicture4($event)"
                style="display: none"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-file-input
            v-model="file1"
              show-size
              placeholder="Select your files"
              prepend-icon="mdi-paperclip"
            ></v-file-input>
          </v-row>
          <v-row>
            <v-col><v-btn @click="cancel()">ยกเลิก</v-btn></v-col>
            <v-col>
              <v-btn color="primary" @click="submit()">บันทึก</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import moment from "moment";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      file1: [],
      loading: false,
      checkValidate: true,
      lazy: false,
      rules: {
        email: [(v) => !!(v || "").match(/@/) || "Please enter a valid email"],
        // length: (len) => (v) =>
        //   (v || "").length >= len ||
        //   `Invalid character length, required ${len}`,
        maxlength13: [(v) => v.length <= 13 || "Max 13 characters"],
        maxlength10: [(v) => v.length <= 10 || "Max 10 characters"],
        maxAge: [
          (v) => v.length <= 2 || "ระบุอายุระหว่าง 12-60ปี เท่านั้น",
          (v) => !!v || "กรุณากรอกข้อมูล",
        ],
        password: [
          (v) =>
            !!(v || "").match(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
            ) ||
            "Password must contain an upper case letter, a numeric character, and a special character",
        ],
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checklogin: [],
      // data: {
      //   station: "",
      //   datefrom: "",
      //   userId: "",
      // },
      data2: {
        station: "",
        datefrom: "",
        userId: "",
        staffName: "",
        q_1_review: "",
        q_1_1: "",
        q_1_2: "",
        q_1_3: "",
        q_1_4: "",
        q_1_5: "",
        q_2_review: "",
        q_2_1: "",
        q_2_2: "",
        q_2_3: "",
        q_2_4: "",
        q_2_5: "",
        q_3_review: "",
        q_3_1: "",
        q_3_2: "",
        q_3_3: "",
        q_3_4: "",
        q_3_5: "",
        q_4_review: "",
        q_4_1: "",
        q_4_2: "",
        q_4_3: "",
        q_4_4: "",
        q_4_5: "",
      },
      data: {
        station: "",
        datefrom: "",
        userId: "",
        staffName: "",
        q_1_1_1: "",
        q_1_1_2: "",
        q_1_1_3: "",
        q_1_1_4: "",
        q_1_1_5: "",
        q_1_1_6: "",
        q_1_1_7: "",
        q_2_1_1: "",
        q_2_1_2: "",
        q_2_1_3: "",
        q_2_1_4: "",
        q_2_2_1: "",
        q_2_2_2: "",
        q_2_2_3: "",
        q_2_2_4: "",
        q_2_2_5_1: "",
        q_2_2_5_2: "",
        q_2_3_1: "",
        q_2_4_2: "",
        q_2_4_3: "",
        q_2_4_4: "",
        q_2_4_5: "",
        q_2_4_6: "",
        q_2_4_7_1: "",
        q_2_4_7_2: "",
        q_2_4_7_3: "",
        q_2_4_7_4: "",
        q_2_4_7_5: "",
        q_2_5_1: "",
        q_2_5_2: "",
        q_2_5_3: "",
        q_2_5_4: "",
        q_2_5_5: "",
        q_2_5_6: "",
        q_2_6_1: "",
        q_2_6_2: "",
        q_2_6_3: "",
        q_2_6_4: "",
        q_2_6_5: "",
        q_2_6_6_1: "",
        q_2_6_6_2: "",
        q_3_1_1: "",
        q_3_1_2: "",
        q_3_1_3: "",
        q_3_1_4: "",
        q_3_1_5: "",
        q_3_1_6: "",
        q_3_1_7: "",
        q_3_1_8: "",
        q_3_1_9: "",
        q_3_1_10: "",
        q_3_2_1: "",
        q_3_2_2: "",
        q_3_2_3: "",
        q_3_2_4: "",
        q_3_2_5: "",
        q_3_3_1: "",
        q_3_3_2: "",
        q_3_3_3: "",
        q_3_3_4: "",
        q_3_3_4_1: "",
        q_3_3_4_2: "",
        q_3_3_4_3: "",
        q_3_3_5: "",
        q_3_3_6: "",
        q_3_4_1: "",
        q_3_4_2: "",
        q_3_4_3: "",
        q_4_1_1: "",
        q_4_1_2: "",
        q_4_1_3: "",
        q_4_1_4: "",
        q_4_1_5: "",
        q_4_1_6: "",
        q_4_1_7: "",
        q_4_1_8_1: "",
        q_4_1_8_2: "",
        q_4_1_8_3: "",
        q_4_1_9_1: "",
        q_4_1_9_2: "",
        q_4_1_10_1: "",
        q_4_1_10_2: "",
        q_4_1_11: "",
        q_4_2_1: "",
        q_4_2_2: "",
        q_4_2_3: "",
        q_4_3_1: "",

        q_1_1_1: "",
        q_1_1_2: "",
        q_1_1_3: "",
        q_1_1_4: "",
        q_1_1_5: "",
        q_1_1_6: "",
        q_1_1_7: "",
        q_2_1_1: "",
        q_2_1_2: "",
        q_2_1_3: "",
        q_2_1_4: "",
        q_2_2_1: "",
        q_2_2_2: "",
        q_2_2_3: "",
        q_2_2_4: "",
        q_2_2_5_1: "",
        q_2_2_5_2: "",
        q_2_3_1: "",
        q_2_4_2: "",
        q_2_4_3: "",
        q_2_4_4: "",
        q_2_4_5: "",
        q_2_4_6: "",
        q_2_4_7_1: "",
        q_2_4_7_2: "",
        q_2_4_7_3: "",
        q_2_4_7_4: "",
        q_2_4_7_5: "",
        q_2_5_1: "",
        q_2_5_2: "",
        q_2_5_3: "",
        q_2_5_4: "",
        q_2_5_5: "",
        q_2_5_6: "",
        q_2_6_1: "",
        q_2_6_2: "",
        q_2_6_3: "",
        q_2_6_4: "",
        q_2_6_5: "",
        q_2_6_6_1: "",
        q_2_6_6_2: "",
        q_3_1_1: "",
        q_3_1_2: "",
        q_3_1_3: "",
        q_3_1_4: "",
        q_3_1_5: "",
        q_3_1_6: "",
        q_3_1_7: "",
        q_3_1_8: "",
        q_3_1_9: "",
        q_3_1_10: "",
        q_3_2_1: "",
        q_3_2_2: "",
        q_3_2_3: "",
        q_3_2_4: "",
        q_3_2_5: "",
        q_3_3_1: "",
        q_3_3_2: "",
        q_3_3_3: "",
        q_3_3_4: "",
        q_3_3_4_1: "",
        q_3_3_4_2: "",
        q_3_3_4_3: "",
        q_3_3_5: "",
        q_3_3_6: "",
        q_3_4_1: "",
        q_3_4_2: "",
        q_3_4_3: "",
        q_4_1_1: "",
        q_4_1_2: "",
        q_4_1_3: "",
        q_4_1_4: "",
        q_4_1_5: "",
        q_4_1_6: "",
        q_4_1_7: "",
        q_4_1_8_1: "",
        q_4_1_8_2: "",
        q_4_1_8_3: "",
        q_4_1_9_1: "",
        q_4_1_9_2: "",
        q_4_1_10_1: "",
        q_4_1_10_2: "",
        q_4_1_11: "",
        q_4_2_1: "",
        q_4_2_2: "",
        q_4_2_3: "",
        q_4_3_1: "",

        review_1: "",
        review_2: "",
        review_3: "",
        review_4: "",
        review_5: "",
        q_1_review: "",
        q_2_review: "",
        q_3_review: "",
        q_4_review: "",

        imgUrl1: "",
        imgUrl2: "",
        imgUrl3: "",
        imgUrl4: "",
        imageName1: "",
        imageName2: "",
        imageName3: "",
        imageName4: "",
      },
      items: [],
      menufrom: false,
      staffName: "",
      // saleCase: "",
      // possessionSaleCase: "",
      // possessionCase: "",
      // drugCase: "",
    };
  },
  async created() {
    var checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));

    console.log(this.checklogin);
    this.data.userId = checklogin.id;
    this.data.station = checklogin.station;

    this.searchDropdown();
    const response = await this.axios.post(
      `${process.env.VUE_APP_API}/researchForms/findAll?station=` +
        this.data.station
    );
    const responsedata2 = await this.axios.post(
      `${process.env.VUE_APP_API}/summaryForms/findAll?station=` +
        this.data.station
    );
    this.data2 = responsedata2.data.data[0];
    console.log("this.data2", responsedata2);
    console.log(response);
    if (response.data.data.length > 0) {
      this.data = response.data.data[0];
      this.data.datefrom = this.convertDate(response.data.data[0].updatedAt);
    }
    const responseUser = await this.axios.get(
      `${process.env.VUE_APP_API}/user/getOne/` + response.data.data[0].userId
    );
    if (responseUser.data.response_status == "SUCCESS") {
      console.log(responseUser);
      this.staffName = responseUser.data.data.name;
      console.log(this.staffName);
    } else {
      this.staffName = "-";
    }
    // var Researchdata = JSON.parse(
    //   Decode.decode(localStorage.getItem("Researchdata"))
    // );
    // this.data = Researchdata;

    this.data = response.data.data[0];
    console.log("this.data", response.data.data);
  },
  methods: {
    convertDate(date) {
      return moment(String(date)).format("MM/DD/YYYY hh:mm");
    },
    cancel() {
      this.$router.push("/manageResearchform");
    },
    async searchDropdown() {
      // const response = await this.axios.get(
      //   `https://policesurveyservice.yuzudigital.com/form/getAllStation`
      // );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/form/getAllStation`
      );
      this.items = response.data.data;
      console.log("search", response.data.data);
      this.items.forEach((item) => {
        // item.searchText = item.station + " " + item.village;
        item.searchText = item.station;
      });
    },
    async submit() {
      console.log(this.file1);
      // var checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      // this.data.reviewUserId = checklogin.id;
      // if (this.$refs.createForm.validate(true)) {
      //   this.loading = true;
      //   // console.log("เข้าเฉย");

      //   console.log("data", this.data);
      //   const response = await this.axios.post(
      //     `${process.env.VUE_APP_API}/researchForms/update`,
      //     this.data
      //   );
      //   console.log("response", response);
      //   if (response.data.response_status === "SUCCESS") {
      //     console.log("response", response);
      //     await this.$swal.fire({
      //       showConfirmButton: false,
      //       timer: 1500,
      //       timerProgressBar: true,
      //       icon: "success",
      //       text: "บันทึกข้อมูลสำเร็จ",
      //     });
      //     this.loading = false;
      //     location.reload();
      //   } else {
      //     await this.$swal.fire({
      //       showConfirmButton: false,
      //       timer: 1500,
      //       timerProgressBar: true,
      //       icon: "error",
      //       text: "บันทึกข้อมูลไม่สำเร็จ",
      //     });
      //   }
      // }
    },
    showPicture1(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    showPicture2(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName2 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.imageBase2 = reader.result;
          this.showImage = URL.createObjectURL(element);
          console.log(this.imageBase2);
        };
      }
    },
    changePic2() {
      document.getElementById("picTure2").click();
    },
  },
};
</script>
<style scoped>
.headtitle {
  font-weight: 800;
  color: #833133;
}
</style>
